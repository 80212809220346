<template>
  <div class="contain500">
    <img src="../../assets/images/500.png" />
    <p>出错啦~稍后再试试吧</p>
    <el-button class="tohomebtn" @click="$router.go(-1)">重新加载</el-button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.contain500 {
  text-align: center;
  padding-top: 100px;
  img {
    width: 400px;
    height: 300px;
  }
  p {
    padding-top: 20px;
    font-size: 14px;
  }
  .tohomebtn {
    background-color: #4277c7;
    color: #ffffff;
    margin-top: 20px;
    padding: 0px 22px !important;
    height: 35px !important;
  }
}
</style>